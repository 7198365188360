import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment/moment";

export async function getOrders({ commit }, data) {
  try {
    commit("setOrderLoading", true);
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    if (data == undefined) {
      data = "/";
    }

    await axios
      .get(`/orders${data}`, { headers })
      .then((response) => {
        let orders = [];
        const {
          totalDocs,
          limit,
          totalPages,
          hasPrevPage,
          hasNextPage,
          prevPage,
          pagingCounter,
          page,
          docs,
        } = response.data;

        docs.forEach(
          ({ charge_id, product, quantity, price, createdAt, ...data }) => {
            createdAt = moment(createdAt).fromNow();

            const productWithPrice = { ...product, price, quantity };

            let existingItem = orders.find(
              (item) => item.charge_id === charge_id
            );

            if (existingItem) {
              existingItem.products.push(product);
            } else {
              orders.push({
                charge_id,
                createdAt,
                products: [productWithPrice],
                ...data,
              });
            }
          }
        );

        commit("setOrders", orders);
        commit("setPagination", {
          totalDocs,
          limit,
          totalPages,
          hasPrevPage,
          hasNextPage,
          prevPage,
          pagingCounter,
          page,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  } catch (e) {
    console.log(e);
  } finally {
    commit("setOrderLoading", false);
    commit("setLoading", false, { root: true });
  }
}

export async function getMyOrders({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    if (data == undefined) {
      data = "/";
    }

    await axios
      .get("/profile/my-orders", { headers })
      .then((result) => {
        let orders = [];
        const {
          totalDocs,
          limit,
          totalPages,
          hasPrevPage,
          hasNextPage,
          prevPage,
          pagingCounter,
          page,
          docs,
        } = result.data;

        docs.forEach((element) => {
          element.createdAt = moment(element.createdAt).format("DD/MM/YYYY");
        });

        // docs.forEach(
        //   ({ charge_id, product, quantity, price, createdAt, ...data }) => {
        //     createdAt = moment(createdAt).fromNow();

        //     const productWithPrice = { ...product, price, quantity };

        //     let existingItem = orders.find(
        //       (item) => item.charge_id === charge_id
        //     );

        //     if (existingItem) {
        //       existingItem.products.push(product);
        //     } else {
        //       orders.push({
        //         charge_id,
        //         createdAt,
        //         products: [productWithPrice],
        //         ...data,
        //       });
        //     }
        //   }
        // );

        commit("setOrders", docs);
        commit("setPagination", {
          totalDocs,
          limit,
          totalPages,
          hasPrevPage,
          hasNextPage,
          prevPage,
          pagingCounter,
          page,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(e);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getOrder({ commit }, id) {
  try {
    commit("setOrderLoading", true);

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };
    await axios
      .get(`/orders/${id}`, { headers })
      .then((response) => {
        response.data.createdAt = moment(response.data.createdAt).format(
          "DD/MM/YYYY"
        );

        commit("setOrder", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("orderError", err.message);
      });
  } catch (e) {
    console.log(e);
    commit("ordertError", err.message);
  } finally {
    commit("setOrderLoading", false);
  }
}

export async function postOrder({ commit }, data) {
  try {
    commit("setOrderLoading", true);

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/orders`, data, { headers })
      .then((response) => {
        commit("setOrder", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("orderError", err.message);
      });
  } catch (e) {
    console.log(e);
    commit("orderError", e.message);
  } finally {
    commit("setOrderLoading", false);
  }
}

export async function updateOrder({ commit }, data) {
  try {
    commit("setOrderLoading", true);
    console.log({ data });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };
    const { _id, ...obj } = data;

    await axios
      .put(`/orders/${_id}`, obj, { headers })
      .then((response) => {
        commit("setSuccess", "order has been updated");
        commit("setOrder", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("orderError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("orderError", e);
  } finally {
    commit("setOrderLoading", false);
  }
}

export async function deleteOrder({ commit }, id) {
  try {
    commit("setOrderLoading", true);

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .delete(`/orders/${id}`, { headers })
      .then((response) => {
        commit("setSuccess", response.data.message);
      })
      .catch((err) => {
        commit("orderError", err.message);
      });
  } catch (e) {
    console.log(e);
    commit("orderError", e.message);
  } finally {
    commit("setOrderLoading", false);
  }
}
