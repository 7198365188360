<template>
  <v-text-field
    v-model="text"
    append-icon="mdi-magnify"
    label="Busqueda"
    single-line
    hide-details
    solo
    flat
    @keypress.native.enter="goSearch"
    @click:append="goSearch"
    dense
  ></v-text-field>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    text: null,
    stock: 1,
    loading: false,
    limit: 30,
    title: '',
    products: [],
    menu: false
  }),

  computed: {
    ...mapState(['search'])
  },

  watch: {
    search(val) {
      !val ? (this.text = null) : this.text;
    }
  },

  created() {
    this.text = this.search;
  },

  methods: {
    goSearch() {
      const { search } = this.$route.query;

      this.$store.commit('setSearchCategories', null);
      this.$store.commit('setBrand', null);

      if (search) {
        this.$store.commit('setSearch', this.text);
        this.$router.replace(
          `/busqueda/?search=${this.text}&order=mayor-minor`
        );
      } else {
        this.$store.commit('setSearch', this.text);
        this.$router.push(`/busqueda/?search=${this.text}&order=mayor-minor`);
      }
    }
  }
};
</script>

<style></style>
