export default {
  user: null,
  isLogged: false,
  error: false,
  errorMessage: "",
  success: false,
  successMessage: "",
  role: null,
  price: 0,
  discount: 0,
  successPassword: false,
  passwordMessage: "",
  passwordErrorMsg: "",
  errorPassword: false,
  info: null,
};
