import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#184e96",
        secondary: colors.lightBlue.darken4,
        blue: "#23b9d7",
      },
    },
  },
});
