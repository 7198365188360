import Vue from "vue";
import Vuex from "vuex";
import swal from "sweetalert2";

import VuexPersistent from "vuex-persist";
import auth from "@/modules/auth";
import Products from "@/modules/products";
import Orders from "@/modules/orders";
import Users from "@/modules/users";
import Categories from "@/modules/categories";
import Cart from "@/modules/cart";
import Customers from "@/modules/customers";
import Quotes from "@/modules/quotes";
import Stores from "@/modules/stores";
import Transfers from "@/modules/transfers";
import Sales from "@/modules/sales";
import Banners from "@/modules/banners";
import Currency from "@/modules/currency";
import Menu from "@/modules/menu";
import Pages from "@/modules/pages";
import Bills from "@/modules/bills";
import locations from "@/modules/locations";
import brands from "@/modules/brands";
Vue.use(Vuex);

const vuexLocal = new VuexPersistent({
  storage: window.localStorage,
  modules: ["auth", "Cart", "Currency"],
});

export default new Vuex.Store({
  state: {
    loading: false,
    search: "",
    search_category: "",
    country: null,
    change: 0,
    pagination: {},
    categoryId: "",
    date: new Date(),
    dates: [new Date()],
    stock_limit: null,
    brand: null,
  },
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setSearch(state, payload) {
      state.search = payload;
    },

    setSearchCategories(state, payload) {
      state.search_category = payload;
    },

    setStockLimit(state, payload) {
      state.stock_limit = payload;
    },

    setBrand(state, payload) {
      state.brand = payload;
    },

    setPagination(state, payload) {
      state.pagination = payload;
    },

    setCategoryId(state, payload) {
      state.categoryId = payload;
    },

    setDate(state, payload) {
      state.date = payload;
    },

    setDates(state, payload) {
      state.dates = payload;
    },

    setCountry(state, payload) {
      state.country = payload;
    },
    setChange(state, payload) {
      state.change = payload;
    },
  },
  actions: {
    newError({ commit }, data) {
      swal.fire({
        icon: "error",
        title: `${data.statusCode} ${data.error}`,
        text: `${data.message}`,
      });
    },

    onLoading({ commit }, data) {
      commit("setLoading", data);
    },

    saveCountry({ commit }, country) {
      // localStorage.setItem("country", country);
      commit("setCountry", country);
    },
  },
  modules: {
    auth,
    Products,
    Categories,
    Cart,
    Orders,
    Users,
    Customers,
    Quotes,
    Stores,
    Transfers,
    Sales,
    Banners,
    Currency,
    Menu,
    Pages,
    Bills,
    locations,
    brands,
  },
  plugins: [vuexLocal.plugin],
});
