import axios from "axios";
// import * as cookies from "vue-cookies";

export async function getBrands({ commit }, data) {
  try {
    if (!data) data = "";

    await axios
      .get(`/brands${data}`)
      .then((result) => {
        const data = result.data;
        commit("setBrands", data);
      })
      .catch((err) => {
        commit("setError", err.response);
        console.log(err);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}
