<template>
  <div>
    <div v-for="(item, i) in categories" :key="i">
      <a class="black--text" @click="navigate(item)"> {{ item.nombre }} </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    category: Object
  },

  data: () => ({
    categories: []
  }),

  mounted() {
    if (this.id) {
      this.getSubCategories();
    }
  },

  methods: {
    async getSubCategories() {
      await this.$http
        .get(`/categories/?level=${3}&categoryId=${this.id}`)
        .then((result) => {
          const categories = result.data;
          if (categories.lenght == 0) {
            this.categories = [];
          }
          this.categories = categories;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    navigate(item) {
      const category = this.category;
      this.$emit('action', [category.nombre, item.nombre]);
    }
  }
};
</script>

<style></style>
