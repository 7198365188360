<template>
  <v-footer
    v-if="role != 'admin' && role != 'employee' && $route.name != 'carrito'"
    class="mt-1"
    color="white"
  >
    <v-row justify="center">
      <v-col cols="12" sm="12" md="8" class="pl-8 mt-8">
        <h3 class="text-uppercase">información de la tienda</h3>
        <p class="text_info mb-1">TECNO TIENDA</p>
        <p class="text_info mb-1">Barcelona 606A</p>
        <p class="text_info mb-1">
          Col Riveras de San Nicolas, San Nicolas de los Garza, N,L.
        </p>
        <p class="text_info mb-1">Llámenos: (81) 1679 1119</p>
        <p class="text_info mb-1">Envíenos un mensaje de correo electrónico:</p>
        <a href="mailto:contacto@tecno-tienda.mx" class="mail_to primary--text">
          contacto@tecno-tienda.mx
        </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pl-8 mt-8">
        <div v-for="(page, i) in pages" :key="i">
          <router-link
            class="text-decoration-none"
            v-if="page.status"
            :to="`/page/${page.title}`"
          >
            {{ page.title }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" class="py-4 text-center primary--text">
        {{ new Date().getFullYear() }} <strong>TECNO TIENDA</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState('auth', ['user', 'isLogged', 'role']),
    ...mapState('Pages', ['pages'])
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions('Pages', ['getPages']),

    initialize() {
      this.getPages(`?limit=20&status=true`);
    }
  }
};
</script>

<style scoped>
.text_info {
  font-size: 17px;
  color: #8c8c8c;
}
.mail_to {
  text-decoration: none;
  font-size: 17px;
}
</style>
